import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Академія DriveMaster
			</title>
			<meta name={"description"} content={"В Академії DriveMaster ми прагнемо надати першокласну освіту водінню, щоб переконатися, що ви станете впевненим, відповідальним і безпечним водієм."} />
			<meta property={"og:title"} content={"Академія DriveMaster"} />
			<meta property={"og:description"} content={"В Академії DriveMaster ми прагнемо надати першокласну освіту водінню, щоб переконатися, що ви станете впевненим, відповідальним і безпечним водієм."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65c4dc440072400020e9c6f3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});